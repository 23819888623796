<template>
  <div style="margin: 50px">
    <a-textarea id="input" v-model="input" @change="convert('')" style="letter-spacing:2px"></a-textarea>
    <a-divider></a-divider>
    <a-button @click="convert('jia')">加</a-button>
    <a-divider type="vertical"/>
    <a-button @click="convert('jian')">减</a-button>
    <a-divider type="vertical"/>
    <a-button @click="convert('cheng')">乘</a-button>
    <a-divider type="vertical"/>
    <a-button @click="convert('chu')">除</a-button>
    <a-divider type="vertical"/>
    <a-button @click="convert('pingfang')">次方</a-button>
    <a-divider type="vertical"/>
    <a-button @click="convert('genhao')">根号</a-button>
    <a-divider type="vertical"/>
    <a-button @click="convert('denghao')">等号</a-button>
    <div id="output"></div>
  </div>
</template>

<script>

export default {
  name: 'mathjax',
  data() {
    return {
      input: ""
    }
  },
  methods: {
    convert: function (symbol) {
      let fieldSplit = this.splitField(symbol)
      this.input = this.recombine(fieldSplit, symbol)
      document.getElementById('input').focus()
      let output = document.getElementById('output');
      output.innerHTML = '';

      MathJax.texReset();
      var options = MathJax.getMetricsFor(output);
      MathJax.tex2svgPromise(this.input, options).then(function (node) {
        output.appendChild(node);
        MathJax.startup.document.clear();
        MathJax.startup.document.updateDocument();
      }).catch(function (err) {
        console.log(err)
      }).then(function () {
      });
    }, splitField() {
      let input_value_array = [];
      let select_field = document.getElementById('input')
      if (select_field.selectionStart === select_field.selectionEnd) {
        input_value_array.push(select_field.value.substring(0, select_field.selectionStart));
        input_value_array.push('');
        input_value_array.push(select_field.value.substring(select_field.selectionStart, select_field.value.length));
      } else {
        let word = '';
        if (document.selection) {
          let sel = document.selection.createRange();
          if (sel.text.length > 0) {
            word = sel.text;
          }
        } else if (select_field.selectionStart || select_field.selectionStart == '0') {
          let startP = select_field.selectionStart;
          let endP = select_field.selectionEnd;
          if (startP != endP) {
            word = select_field.value.substring(startP, endP);
          }
        }
        input_value_array.push(select_field.value.substring(0, select_field.selectionStart));
        input_value_array.push(word)
        input_value_array.push(select_field.value.substring(select_field.selectionEnd, select_field.value.length));
      }
      return input_value_array
    },
    recombine: (fieldSplit, symbol) => {
      let result = fieldSplit[0]
      if (symbol === 'jia') {
        result += '+' + fieldSplit[1]
      } else if (symbol === 'jian') {
        result += '-' + fieldSplit[1]
      } else if (symbol === 'cheng') {
        result += 'x' + fieldSplit[1]
      } else if (symbol === 'chu') {
        result += '\\cfrac{' + fieldSplit[1] + '}'
      } else if (symbol === 'pingfang') {
        result += fieldSplit[1] + '^{2}'
      } else if (symbol === 'genhao') {
        result += '\\sqrt[2]{' + fieldSplit[1] + '}'
      }else if (symbol === 'denghao') {
        result += fieldSplit[1] +'='
      } else {
        result += fieldSplit[1]
      }
      result += fieldSplit[2]
      return result
    }
  }
}

</script>

<style scoped>

</style>